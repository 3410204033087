/**
 * This is the page that stripe redirects to
 * once the user's oauth onboarding is complete.
 *
 * This page performs the last required stripe request
 * and then updates the user's database entry
 */
import React from 'react'
import { Link, navigate } from 'gatsby'

import '../style.scss'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import LoadingDots from 'components/shared/Forms/LoadingDots'

class RedirectPage extends React.Component {
  state = { error: null }
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search),
      code = params.get('code')
    // state = params.get("state") // TODO: HANDLE CSRF
    if (!code) {
      return navigate('/')
    } else {
      this.finishAuthentication(code)
    }
  }

  async finishAuthentication(code) {
    try {
      await this.props.auth.stripeAuthentication(code)
      navigate('/dashboard/settings')
    } catch (error) {
      console.log(error)
      this.setState({ error: true })
    }
  }

  render() {
    return (
      <div className="base">
        <div className="content-wrap">
          <section className="form-title page-title">
            {this.state.error ? (
              <h2>Something has gone wrong!</h2>
            ) : (
              <h2>Sign up for Payments</h2>
            )}
          </section>
          <section className="onboarding-page-container">
            <div className="onboarding-page-subcontainer">
              {this.state.error ? (
                <p className="hint-text redirect-txt">
                  Don't worry. The developers have been alerted so please try
                  again later!
                  <br />
                  Click <Link to={'/dashboard/settings'}>Here</Link> to go back
                  to the dashboard.
                </p>
              ) : (
                <p className="hint-text redirect-txt">
                  Completing Registration. Please wait to be redirected
                </p>
              )}
              <div className="loading-animation">
                <LoadingDots loading={!this.state.error} />
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default props => (
  <Layout {...props} displayFooter={false}>
    <SEO />
    <RedirectPage {...props} />
  </Layout>
)
